import { openModal } from "./modal";

// Variables
const form = document.querySelector("form");

form.addEventListener("submit", async (event) => {
  event.preventDefault();
  let result;
  try {
    var requestOptions = {
      method: "POST",
      body: new FormData(form),
      redirect: "follow",
    };

    const response = await fetch("api/mail", requestOptions);
    const body = await response.json();
    if (body["status_message"] === 0) {
      result = await openModal(
        "Le formulaire de contact a été soumis avec succès. Merci, je vous recontacterai bientôt!",
        "Confirmer"
      );
    } else {
      result = await openModal(
        "Une erreur s'est produite lors de la soumission du formulaire. Veuillez réessayer plus tard",
        "Confirmer"
      );
    }
  } catch (error) {
    console.log(error);
    result = await openModal(
      "Une erreur s'est produite lors de la soumission du formulaire. Veuillez réessayer plus tard",
      "Confirmer"
    );
  }
});
